import { cn } from "@lib/cn"
import React, { forwardRef, HTMLAttributes } from "react"

export type ContainerSize = "small" | "medium" | "big"

type ContainerProps = HTMLAttributes<HTMLDivElement> & {
  as?: "div" | "section"
  size?: ContainerSize
}

export const Container = forwardRef<ContainerProps, any>(
  (props: ContainerProps, ref) => {
    const { className, as = "section", size = "medium", ...rest } = props
    const Comp = as
    return (
      <Comp
        className={cn(
          "relative mx-auto w-[100%-2.5rem]",
          size === "small" && "max-w-[728px]",
          size === "medium" && "max-w-[1088px]",
          size === "big" && "max-w-[1312px]",
          className,
        )}
        data-size={size}
        {...rest}
        // @ts-ignore
        ref={ref}
      ></Comp>
    )
  },
)

Container.displayName = "Container"

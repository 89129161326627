import { Logo } from "@components/Logo"
import { Container } from "@components/ui/Container"
import { HeadingLink } from "@components/ui/heading-link"
import { Heading } from "@components/ui/typograhpy/Heading"
import Link from "next/link"
import { FC } from "react"
import { FooterSingleton } from "__generated__/sanity"
import { ContentPageFooterLink } from "./Link"
import Image from "next/image"

type ContentPageFooterProps = FooterSingleton

export const ContentPageFooter: FC<ContentPageFooterProps> = ({
  linksFirstColumn,
  linksSecondColumn,
}) => {
  return (
    <footer>
      <div className="border-t border-b border-border">
        <Container size="medium" className="flex items-center justify-center text-center py-24 px-0 flex-col md:py-[6.5rem] md:px-0 lg:py-40" as="div">
          <Heading variant="h1" as="h2" className="mb-0">
            Piqued your interest?
          </Heading>
          <Link href="/app" passHref legacyBehavior>
            <HeadingLink color="gradient" variant="internal" size="h1">
              Try for free
            </HeadingLink>
          </Link>
        </Container>
      </div>
      <div className="flex justify-center">
        <div className="flex border border-t-0 rounded-[0_0_2px_2px] overflow-hidden">
          <Image
            src="/assets/swiss-flag.png"
            alt="Swiss flag"
            width={17}
            height={17}
          />
        </div>
      </div>
      <Container
        size="medium"
        as="div"
        className="py-16 grid items-start grid-cols-[1fr] gap-10 md:grid-cols-[1fr_2fr_1fr] md:gap-4"
      >
        <Logo variant="icon-only" />
        <div className="grid grid-cols-[1fr_1fr] gap-4">
          <div>
            {linksFirstColumn.map((link) => (
              <ContentPageFooterLink {...link} key={link._key} />
            ))}
          </div>
          <div>
            {linksSecondColumn.map((link) => (
              <ContentPageFooterLink {...link} key={link._key} />
            ))}
          </div>
        </div>
        {/* <ContentPageFooterForm /> */}
      </Container>
    </footer>
  )
}

import { Span } from "@components/ui/typograhpy/Span"
import NextLink from "next/link"
import React, { FC } from "react"
import { FooterSingleton } from "__generated__/sanity"

type ContentPageFooterLinkProps = FooterSingleton["linksFirstColumn"]["0"]

export const ContentPageFooterLink: FC<ContentPageFooterLinkProps> = ({
  text,
  href,
  type,
}) => {
  const isExternal = type === "external"

  return (
    <>
      {isExternal ? (
        <a
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          className="block mb-4"
        >
          <Span variant="button-label-big">{text}</Span>
        </a>
      ) : (
        <NextLink href={href} passHref legacyBehavior>
          <Span variant="button-label-big" as="a" className="block mb-4">
            {text}
          </Span>
        </NextLink>
      )}
    </>
  )
}

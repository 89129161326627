import { Logo } from "@components/Logo"
import { Container } from "@components/ui/Container"
import { Span } from "@components/ui/typograhpy/Span"
import Link from "next/link"
import { FC } from "react"
import { AnimationVariant } from "../layout"
import { ContentPageHeaderMobileMenu } from "./MobileMenu"
import { cn } from "@lib/cn"

export const internalLinks = [
  {
    href: "/pricing",
    title: "Pricing",
  },
]

type ContentPageHeaderProps = {
  animationVariant: AnimationVariant
}

export const ContentPageHeader: FC<ContentPageHeaderProps> = ({
  animationVariant,
}) => {
  return (
    <>
      <div className="relative w-full h-[4.5rem]" />
      <nav
        className={cn(
          "fixed w-full left-0 top-0 z-[10] bg-content-header bg-repeat-[initial] bg-[length:4px_4px] backdrop-blur-[4px] perspective-[1000px] rotate-[35deg] -translate-y-2 opacity-0",
          animationVariant === "animate" && "animate-fade-in",
          animationVariant === "visible" && "opacity-100 perspective-[1000px] rotate-0 translate-y-0"
        )}
        data-animation-variant={animationVariant}
      >
        <Container
          className="grid py-4 justify-between left-0 top-0 grid-cols-[auto_1fr] gap-4 lg:grid-cols-[1fr_auto_1fr] focus-visible:outline-0 focus-visible:rounded-[3px] focus-visible:shadow-outline"
          as="div"
        >
          <Link href="/" className="relative py-2 overflow-hidden">
            <div className="hidden lg:block"><Logo variant="full" /></div>
            <div className="block lg:hidden"><Logo variant="icon-only" /></div>
          </Link>
          <div className="hidden lg:flex items-center gap-8">
            <Span
              as="a"
              href="https://docs.viesus.cloud"
              target="blank"
              variant="button-label-small"
            >
              Docs
            </Span>
            {internalLinks.map((link) => (
              <Link href={link.href} passHref key={link.href} legacyBehavior>
                <Span as="a" variant="button-label-small">
                  {link.title}
                </Span>
              </Link>
            ))}
          </div>
          <div className="flex items-center justify-end gap-5">
            <Link href="/login" passHref legacyBehavior>
              <Span as="a" variant="button-label-small">
                Login
              </Span>
            </Link>
            <Link href="/signup/create-account" passHref legacyBehavior>
              <Span
                as="a"
                variant="button-label-small"
                className="ml-4"
              >
                Try for free
              </Span>
            </Link>
            <ContentPageHeaderMobileMenu />
          </div>
        </Container>
      </nav>
    </>
  )
}

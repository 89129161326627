import { cn } from "@lib/cn"
import { SanitySeo } from "@lib/sanity/seo-type"
import { FooterSingleton } from "__generated__/sanity"
import { NextSeoProps } from "next-seo"
import { useRouter } from "next/router"
import { FC, ReactNode, useEffect, useState } from "react"
import { ContentPageFooter } from "../footer"
import { ContentPageHeader } from "../header"
import { ContentPageSeo } from "../SEO"

type ContentPageLayoutProps = {
  footer: FooterSingleton
  seo: NextSeoProps & SanitySeo
  children?: ReactNode
}
export type AnimationVariant = "animate" | "visible" | "hidden"

export const ContentPageLayout: FC<ContentPageLayoutProps> = ({
  children,
  footer,
  seo,
}) => {
  const { isReady } = useRouter()
  const [animationVariant, setAnimationVariant] =
    useState<AnimationVariant>("animate")

  useEffect(() => {
    // Disabled for testing 👇
    // setAnimationVariant("visible")
    setAnimationVariant("animate")
  }, [isReady])

  return (
    <>
      <ContentPageSeo {...seo} />
      <div>
        <ContentPageHeader animationVariant={animationVariant} />
        <div
          className={cn(
            "block opacity-0",
            animationVariant === "animate" && "animate-content-fade opacity-0 overflow-x-hidden",
            animationVariant === "visible" && "opacity-100"
          )}
          data-animation-variant={animationVariant}
        >
          {children}
        </div>
        <span
          className={cn(
            "block fixed w-screen h-screen left-0 top-0 pointer-events-none z-[5] bg-background mask-image-radial",
            animationVariant === "animate" && "animate-scale-in",
            animationVariant === "visible" && "opacity-0"
          )}
          data-animation-variant={animationVariant}
        />
        <ContentPageFooter {...footer} />
      </div>
    </>
  )
}

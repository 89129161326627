import { Button } from "@components/ui/button"
import { CleanIconButton } from "@components/ui/button/CleanIconButton"
import IconMenu from "@components/ui/icons/IconMenu"
import { Overlay } from "@components/ui/overlay"
import { Span } from "@components/ui/typograhpy/Span"
import Link from "next/link"
import { FC } from "react"
import { internalLinks } from "./index"
import { cn } from "@lib/cn"

export const ContentPageHeaderMobileMenu: FC = () => {
  return (
    <div className="block lg:hidden">
      <Overlay
        trigger={
          <CleanIconButton label="menu" className="ml-0">
            <IconMenu />
          </CleanIconButton>
        }
        title="Menu"
      >
        <Span
          variant="button-label-big"
          className={cn(
            "flex w-full text-left text-white items-center px-5 h-16 border-b border-border bg-transparent transition-[background-color] duration-200",
            "data-[is-first]:border-t data-[is-first]:border-border active:bg-border-opacity"
          )}
          href="https://docs.viesus.cloud"
          target="blank"
          as="a"
        >
          Docs
        </Span>
        {internalLinks.map((link) => (
          <Link href={link.href} passHref key={link.href} legacyBehavior>
            <Span
              variant="button-label-big"
              className={cn(
                "flex w-full text-left items-center px-5 h-16 border-b border-border bg-transparent transition-[background-color] duration-200",
                "data-[is-first]:border-t data-[is-first]:border-border active:bg-border-opacity"
              )}
              as="a"
            >
              {link.title}
            </Span>
          </Link>
        ))}
        <div className="p-5 mt-10 flex justify-center items-center gap-5 flex-col border-t border-b border-border">
          <Link href="/login" passHref legacyBehavior>
            <Span as="a" variant="button-label-small">
              Login
            </Span>
          </Link>
          <Link href="/signup/create-account" passHref legacyBehavior>
            <Button as="link" variant="primary" size="small" width="fill">
              Try for free
            </Button>
          </Link>
        </div>
      </Overlay>
    </div>
  )
}
